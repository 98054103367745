import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router";

import VideoModal from "../components/modal/VideoModal"

export default function useModal(props) {
  const location = useLocation()

  const [modalVisible, toggleModal] = useState(false)

  const renderModal = (children) => (
    <VideoModal visible={modalVisible} 
      title={props.title} closeModal={closeModal}
      responsiveFluid={props.responsiveFluid}
    >
      {children}
    </VideoModal>
  )

  const openModal = () => {
    toggleModal(true)
  }
  
  const closeModal = (e) => {
    window.location.hash = "_"
    toggleModal(false)
  }

  useEffect(() => {
    if (!location.hash && !props.location) 
      return

    if (location.hash === props.location) {
      toggleModal(true)
    } else {
      toggleModal(false)
    }
  }, [location.hash])


  return [renderModal, openModal, closeModal]
}